import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import { DynamicChangeEventClass } from "./DynamicChangeEventClass";
import DinamicallyForm from "@/components/DinamicallyForm/DinamicallyForm.vue";
import { FormItem } from "@/components/DinamicallyForm/FormItem";
import { datatypes } from "@/components/DataTableGenerico/datatypes";
import { MessageService } from "@/shared/services/message-service";
import i18n from "@/i18n/i18n";
import { UtilsString } from "@/utils/utils-string";
import { ComponentIntance } from "@/components/DinamicallyForm/ComponentIntance";
import { BaseDto } from "@/shared/dtos/base-dto";
import { UtilsNumber } from "@/utils/utils-number";
import { ComponentsTypes } from "@/components/DinamicallyForm/TipoComponentes";
import { UtilsBoolean } from "@/utils/utils-boolean";
import { ButtonAction } from "@/components/DinamicallyForm/ButtonAction";
@Component({
  components: { DinamicallyForm },
})
export default class Dinamically extends Vue {
  @Ref("Formulario") Formulario!: DinamicallyForm;
  public FieldsForRow: FormItem[] = [];
  public FormReadOnly: boolean = false;
  public BotonGuardar: boolean = true;
  public BotonArchivar: boolean = false;
  public BotonCancelar: boolean = true;
  public BotonClonar: boolean = false;
  public EstoyCreado: boolean = true;
  //Para controlar el clonado en casos especiales y que no pase por editar si no por otro metodo, GuardadoClonado()
  public EstoyClonandoEspecifico: boolean = false;
  public textos_tabs: any[] = [];
  public VariableNameCodigo: string = "";
  public BasicElement: BaseDto = new BaseDto();
  public IsDeveloper = false;
  public ActionButtons: ButtonAction[] = [];

  private heReabiertoElForm: boolean = false;

  public async created() {
    this.IsDeveloper = !UtilsBoolean.Anytoboolean(
      process.env.VUE_APP_PRODUCCION
    );
    await this.OpenForm();
  }

  private async OpenForm() {
    await this.OnCreated();
    this.DefaultDataBeforeCreated();
    this.CreateNameTabs();
    this.BeforeCreateFields();
    this.CreateFields();
    this.AfterCreateItems();
  }

  public ReopenFormWithId(id: string) {
    this.$router.push({
      name: UtilsString.ValueOf(this.$route.name),
      params: { id: id },
    });
    this.ClearFormItems();
    //@ts-ignore
    this.Formulario.ClearDataSaved();
    this.OpenForm();
    this.SetManualTypeForm(false);
    this.heReabiertoElForm = true;
  }

  public mounted() {
    this.SetInstaciaForDynamically();
  }
  public SetInstaciaForDynamically() {
    //@ts-ignore
    this.Formulario.Helper = this;
  }

  public AddTabName(name: string, meta_data: any = undefined) {
    this.textos_tabs.push({
      nametab: UtilsString.ValueOf(this.GetTraduccion(name)),
      metadata: meta_data,
    });
    return {
      textos_tabs: this.textos_tabs,
      indexthis: this.textos_tabs.length - 1,
    };
  }

  public SetTabName(num_tab: number, name: string, meta_data: any = undefined) {
    this.textos_tabs[num_tab - 1].nametab = UtilsString.ValueOf(
      this.GetTraduccion(name)
    );
    this.textos_tabs[num_tab - 1].metadata = meta_data;
    Vue.set(this.textos_tabs, num_tab - 1, this.textos_tabs[num_tab - 1]);
    return {
      textos_tabs: this.textos_tabs,
      indexthis: num_tab - 1,
      num: num_tab,
    };
  }

  public AddFormItem(
    data: any,
    variableName: string,
    caption: string,
    datatype: datatypes,
    maxLength: number,
    intab: number = 1,
    Editable: ComponentIntance = ComponentIntance.Siempre,
    visible: ComponentIntance = ComponentIntance.Siempre
  ): FormItem {
    this.FieldsForRow.push(
      new FormItem(
        data,
        caption,
        variableName,
        datatype.toString(),
        this.getIntanceOfBooleanLogic(visible),
        maxLength,
        intab
      )
    );
    if (Editable === ComponentIntance.Siempre) {
      Editable = ComponentIntance.Nunca;
    }
    return this.FieldsForRow[this.FieldsForRow.length - 1].isReadOnly(
      this.getIntanceOfBooleanLogic(Editable)
    );
  }

  public AddFormItemDataDefaultString(
    data: any,
    caption: string,
    variableName: string,
    maxLength: number = 100,
    intab: number = 1
  ): FormItem {
    this.FieldsForRow.push(
      new FormItem(
        data,
        caption,
        variableName,
        datatypes.string.toString(),
        true,
        maxLength,
        intab
      )
    );
    return this.FieldsForRow[this.FieldsForRow.length - 1];
  }

  public AddFormItemDataDefaultNumber(
    data: any,
    caption: string,
    variableName: string,
    intab: number = 1,
    maxlength: number = datatypes.maxlengthinteger,
    visible: boolean = true
  ): FormItem {
    this.FieldsForRow.push(
      new FormItem(
        data,
        caption,
        variableName,
        datatypes.number.toString(),
        visible,
        maxlength,
        intab
      )
    );
    return this.FieldsForRow[this.FieldsForRow.length - 1];
  }

  public AddFormItemDataDefaultDate(
    data: any,
    caption: string,
    variableName: string,
    intab: number = 1
  ): FormItem {
    let fechaFormateada = undefined;
    let horaFormateada = undefined;
    let hora = undefined;
    if (!(data instanceof Date)) {
      if (UtilsString.ValueOf(data) !== "") {
        if (data.includes("T00:00:00")) {
          data = UtilsString.ValueOf(data).replace("T00:00:00", "");
        } else {
          hora = UtilsString.ValueOf(data).match(
            /\T\d{2}\:\d{2}\:\d{2}\.\d+|\T\d{2}\:\d{2}\:\d{2}/
          );
          data = UtilsString.ValueOf(data).replace(
            /\T\d{2}\:\d{2}\:\d{2}\.\d+|\T\d{2}\:\d{2}\:\d{2}/,
            ""
          );
        }
        fechaFormateada = this.formatDate(data);
        horaFormateada = this.formatHour(hora);

        if (horaFormateada !== null)
          fechaFormateada = fechaFormateada + " " + horaFormateada;
      }
    } else {
      let fecha: Date = new Date(data);
      data = `${fecha.getFullYear()}-${UtilsNumber.numberToStringFormat(
        fecha.getMonth() + 1,
        2
      )}-${UtilsNumber.numberToStringFormat(fecha.getDate(), 2)}`;
      fechaFormateada = this.formatDate(data);
    }
    this.FieldsForRow.push(
      new FormItem(
        data,
        caption,
        variableName,
        datatypes.date.toString(),
        true,
        datatypes.maxlengthDate,
        intab
      ).CreateDate()
    );
    let form = this.FieldsForRow[this.FieldsForRow.length - 1];
    form.dateFormatted = UtilsString.ValueOf(fechaFormateada);
    return this.FieldsForRow[this.FieldsForRow.length - 1];
  }

  public AddFormItemDataDefaultDateTime(
    data: any,
    caption: string,
    variableName: string,
    intab: number = 1
  ): FormItem {
    this.FieldsForRow.push(
      new FormItem(
        data,
        caption,
        variableName,
        datatypes.datetime.toString(),
        true,
        datatypes.maxlengthDate,
        intab
      ).CreateDateTime()
    );
    return this.FieldsForRow[this.FieldsForRow.length - 1];
  }

  public ParseDateForDataItem(data: any) {
    if (!(data instanceof Date)) {
      if (UtilsString.ValueOf(data) !== "") {
        data = UtilsString.ValueOf(data).replace("T00:00:00", "");
      }
    } else {
      let fecha: Date = new Date(data);
      data = `${fecha.getFullYear()}-${UtilsNumber.numberToStringFormat(
        fecha.getMonth() + 1,
        2
      )}-${UtilsNumber.numberToStringFormat(fecha.getDate(), 2)}`;
    }
    return data;
  }

  public formatDate(date: any) {
    if (!date) return null;
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  private formatHour(date: any) {
    if (!date) return null;

    date = UtilsString.ValueOf(date).substring(1);

    if (UtilsString.ValueOf(date).match(/\d{2}\:\d{2}\:\d{2}\.\d+/) !== null) {
      date = UtilsString.ValueOf(date).replace(/\.\d+/, "");
    }
    return date;
  }

  public AddFormItemDataDefaultBoolean(
    data: any,
    caption: string,
    variableName: string,
    intab: number = 1,
    Texton: string,
    Textoff: string
  ): FormItem {
    data = UtilsBoolean.Anytoboolean(data);
    this.FieldsForRow.push(
      new FormItem(
        data,
        caption,
        variableName,
        datatypes.boolean.toString(),
        true,
        datatypes.maxlengthinteger,
        intab
      ).CreateSwitch(Texton, Textoff)
    );
    return this.FieldsForRow[this.FieldsForRow.length - 1];
  }
  public AddFromItemCodigo(
    data: any,
    caption: string,
    intab: number = 1
  ): FormItem {
    this.VariableNameCodigo = caption;
    return this.AddFormItem(
      data,
      caption,
      datatypes.string,
      datatypes.maxCodigo,
      intab,
      ComponentIntance.SoloCreacion
    )
      .isRequired()
      .isMayuscula(true)
      .thisIsCodigo();
  }

  public AddFormItemDataDefaultSlot(
    data: any,
    caption: string,
    variableName: string,
    maxLength: number,
    intab: number = 1
  ): FormItem {
    this.FieldsForRow.push(
      new FormItem(
        data,
        caption,
        variableName,
        datatypes.string.toString(),
        true,
        maxLength,
        intab
      ).SetComponent(ComponentsTypes.Slot)
    );
    return this.FieldsForRow[this.FieldsForRow.length - 1];
  }

  /**
   * AddButtonAction
   */
  public AddButtonAction(NewButton: ButtonAction): ButtonAction {
    NewButton = this.OperativaBotonesVisualizacion(NewButton);
    this.ActionButtons.push(NewButton);
    return this.ActionButtons[this.ActionButtons.length - 1];
  }

  /**
   * OperativaBotonesVisualizacion
   */
  private OperativaBotonesVisualizacion(Button: ButtonAction): ButtonAction {
    switch (Button.Crear) {
      case ComponentIntance.Nunca:
        Button.visible = false;
        break;
      case ComponentIntance.Siempre:
        Button.visible = true;
        break;
      case ComponentIntance.SoloCreacion:
        Button.visible = this.EstoyCreado;
        break;
      case ComponentIntance.SoloEdicion:
        Button.visible = !this.EstoyCreado;
        break;
    }
    return Button;
  }
  /**
   * Crea un botón de acciones por defecto
   */
  public AddDefaultButtonAction(
    Caption: string,
    EventMethod: (...args: any[]) => void,
    Icono: string = "",
    Crear: ComponentIntance = ComponentIntance.Siempre
  ): ButtonAction {
    let btn = new ButtonAction();

    btn.Title = this.GetTraduccion(Caption);
    btn.EventMethod = EventMethod;
    btn.Icono = Icono;
    btn.Crear = Crear;
    return this.AddButtonAction(btn);
  }

  public isVisibleActionButton(caption: string, visible: boolean) {
    this.ActionButtons.filter(
      (button: ButtonAction) => button.Title === this.GetTraduccion(caption)
    )[0].visible = visible;
  }

  public ClearFormItems() {
    this.FieldsForRow = [];
  }
  public UpdateDataItem(variableName: string, data: any) {
    this.FieldsForRow.forEach((element) => {
      if (element.variableName === variableName) {
        element.data = data;
      }
    });
  }
  public SetVisibilityFormItemsForTab(num_tab: number, visible: boolean) {
    let fields_in_tab = this.FieldsForRow.filter((x) => x.intab === num_tab);
    fields_in_tab.forEach((element) => {
      element.isVisible(visible);
    });
  }

  public SetFormReadOnly(value: boolean) {
    this.FormReadOnly = value;
  }

  public GetTraduccion(name: string) {
    return UtilsString.ValueOf(i18n.t(name.toLowerCase()));
  }
  public GetDataItem(variableName: string) {
    for (let i = 0; i < this.FieldsForRow.length; i++) {
      const element = this.FieldsForRow[i];
      if (element.variableName === variableName) {
        return element.data;
      }
    }
    return undefined;
  }

  public GetFormReopen(): boolean {
    return this.heReabiertoElForm;
  }

  public GetObjecInstance(): BaseDto {
    //@ts-ignore
    return new BaseDto(this.Formulario.GetObjectForm());
  }
  public CallMeIfObjecInstanceIfIsValid(EventMethod: (...args: any[]) => void) {
    if (!this.FormReadOnly) {
      //@ts-ignore
      this.Formulario.ValidatePromise()
        .then((allOk: boolean) => {
          if (allOk) {
            EventMethod(this.GetObjecInstance());
          }
        })
        .catch(() => {});
    }
  }

  public ReasignData<T>(source: T, destination: T) {
    let variables = Object.getOwnPropertyNames(source);
    for (let i = 0; i < variables.length; i++) {
      if (variables[i] !== "") {
        eval(
          "destination." + variables[i] + "= this.GetDataItem(variables[i])"
        );
      }
    }
    return destination;
  }
  public SenToast(i18n_key: string) {
    MessageService.toast(UtilsString.ValueOf(i18n.t(i18n_key.toLowerCase())));
  }

  public SenToastWarning(i18n_key: string) {
    MessageService.toast(
      UtilsString.ValueOf(i18n.t(i18n_key.toLowerCase())),
      MessageService.TypeWarning
    );
  }

  public ShowLoading() {
    MessageService.showLoading();
  }

  public HideLoading() {
    MessageService.hideLoading();
  }

  public SetFormItem(variableName: string): FormItem {
    let fields = this.FieldsForRow.filter(
      (x) => x.variableName === variableName
    );
    if (fields.length > 0) {
      return fields[0];
    } else {
      return new FormItem(null, "", variableName, "", false, 0);
    }
  }

  public SenToastClonado() {
    this.SenToast("dynamically_form.regclonado");
  }
  public SenToastGuardado() {
    this.SenToast("dynamically_form.regguardado");
  }
  public SenToastActualizado() {
    this.SenToast("dynamically_form.regactualizado");
  }

  public getparamId() {
    let params = { HayParametro: false, Parametro: -1 };
    try {
      if (this.$route.params.id !== undefined) {
        try {
          if (Number.parseInt(UtilsString.ValueOf(this.$route.params.id)) > 0) {
            this.EstoyCreado = false;
            params.HayParametro = true;
            params.Parametro = Number.parseInt(
              UtilsString.ValueOf(this.$route.params.id)
            );
          }
        } catch (error) {}
      }
    } catch (error) {}
    return params;
  }

  public getQueryparam(param: any) {
    let params = { HayParametro: false, Parametro: -1 };

    if (param !== undefined) {
      try {
        if (Number.parseInt(UtilsString.ValueOf(param)) > 0) {
          params.HayParametro = true;
          params.Parametro = Number.parseInt(UtilsString.ValueOf(param));
        }
      } catch (error) {}
    }
    return params;
  }

  public getQueryparamString(param: any) {
    let params = { HayParametro: false, Parametro: "" };
    if (param !== undefined) {
      params.HayParametro = true;
      params.Parametro = param;
    }
    return params;
  }

  public getIntanceOfBooleanLogic(Intance: ComponentIntance): boolean {
    let bool: boolean = true;
    switch (Intance) {
      case ComponentIntance.Siempre:
        bool = true;
        break;
      case ComponentIntance.Nunca:
        bool = false;
        break;
      case ComponentIntance.SoloCreacion:
        bool = !this.EstoyCreado;
        break;
      case ComponentIntance.SoloEdicion:
        bool = this.EstoyCreado;
        break;
    }
    return bool;
  }

  @Watch("EstoyCreado")
  public ControlDeCreacion() {
    //Los botones se mapean para asignar la visualizacion rapida de estos
    this.ActionButtons = this.ActionButtons.map((x: ButtonAction) =>
      this.OperativaBotonesVisualizacion(x)
    );
    this.ChangeTypeForm();
    //Deshabilitamos el boton de clonar cunado estemos en creacion ya que de poco sirve clonar cuando no existe
    if (this.EstoyCreado) {
      this.BotonClonar = false;
      this.ChangeTypeFormToCreando();
    } else {
      this.BotonClonar = true;
      this.ChangeTypeFormToEditando();
    }
  }

  public HandlerSave(obj: BaseDto) {
    obj = new BaseDto(obj);
    let aux = this.Save(obj);
    if (aux !== undefined) {
      obj = aux;
    }
    if (this.IsDeveloper) {
      if (this.EstoyCreado) {
        console.log("Objeto que se pasa a la API para Insertar", obj.toJson());
      } else {
        console.log(
          "Objeto que se pasa a la API para Actualizar",
          obj.toJson()
        );
      }
    }
    obj = this.BeforeSave(obj);
    if (this.EstoyClonandoEspecifico) {
      this.GuardadoClonado(obj);
    } else if (this.EstoyCreado) {
      this.BeforeInsertar();
      this.Insertar(obj);
      this.AfterInsertar();
    } else {
      obj.id = this.BasicElement.id;
      obj.id_cliente = this.BasicElement.id_cliente;
      this.BeforeActualizar();
      this.Actualizar(obj);
      this.AfterActualizar();
    }
    this.AfterSave(obj);
  }

  public AllSaveOk() {
    if (this.EstoyCreado) {
      setTimeout(() => this.SenToastGuardado(), 500);
    } else {
      setTimeout(() => this.SenToastActualizado(), 500);
    }
    this.Close();
  }
  public Clonar(obj: any) {
    let frm = this.SetFormItem(this.VariableNameCodigo);
    if (frm !== undefined) {
      frm.readonly = false;
      this.SenToastClonado();
      this.BotonClonar = false;
      this.BasicElement.id = 0;
    }
  }
  public Cancelar() {
    //@ts-ignore
    this.Formulario.ClearDataFrom();
    this.Close();
  }

  public Close() {
    this.$router.back(); //Cambiado para abrir formulario en popup
  }

  //Se ejecuta sincronamente desdpues del created de manera que tenemos un hilo que seguir
  public OnCreated() {}
  //Creación de los items de la pantalla
  public CreateItems() {}
  //Llamada al metodo cuando se quiere poner datos por defecto ala variable solo cunado la pantalla
  // sea de inserción
  public DefaultDataBeforeCreated() {}

  //Llamada al metodo cuando se hayan creado todos los datos en la pantalla
  public AfterCreateItems() {}

  public Save(obj: any): any {
    return obj;
  }
  public Insertar(obj: any) {}
  public Actualizar(obj: any) {}
  public GuardadoClonado(obj: any) {}

  public BeforeSave(obj: any) {
    return obj;
  }
  public BeforeInsertar() {}
  public BeforeActualizar() {}
  public AfterInsertar() {}
  public AfterActualizar() {}
  public AfterSave(obj: any) {}

  public EventChange(event: DynamicChangeEventClass) {}
  public DontSave(event: any) {
    if (this.IsDeveloper) {
      if (event !== undefined) {
        console.log("No se pudo guardar", event);
      }
    }
    this.SenToastWarning("dynamically_form.regnosepuedeguardar");
  }

  public CreateNameTabs() {}
  public CreateFields() {}
  public BeforeCreateFields() {}
  /**
   * SetManualTypeForm
   */
  public SetManualTypeForm(edicion: boolean) {
    this.EstoyCreado = !edicion;
  }
  public ChangeTypeForm() {
    //formulario tipo cambia. es decir si es de edicion o de creacion
  }
  public ChangeTypeFormToEditando() {
    //Cambia el formulario a modificacion
    this.EstoyCreado = false;
  }

  public ChangeTypeFormToCreando() {
    //Cambia el formulario a Creacion
    this.EstoyCreado = true;
  }

  public ChangeTypeFormToClonar() {
    this.EstoyClonandoEspecifico = true;
  }
}
