import { BaseDto } from '@/shared/dtos/base-dto';
import { ComponentIntance } from './ComponentIntance';

export class ButtonAction extends BaseDto {
    public Title!: string;
    public Crear!: ComponentIntance;
    public Icono!:string;
    public EventMethod!: (...args: any[]) => void;
    //Campo control interno
    public visible!:boolean;
}