





















































































































































































































































































































































































import {
  Component,
  Prop,
  Watch,
  PropSync,
  Ref,
  Vue,
} from "vue-property-decorator";
import { FormItem } from "./FormItem";
import { datatypes } from "@/components/DataTableGenerico/datatypes";
import { KeyboardKeyCodes } from "@/utils/keyboardKeyCodes";
import { UtilsString } from "@/utils/utils-string";
import { DynamicChangeEventClass } from "@/components/DinamicallyForm/DynamicChangeEventClass";
import {
  EventGuardar,
  EventCancelar,
  EventClonar,
  EventChange,
  EventDontSave,
  EventArchivar,
} from "./Events-Dynamic-Form";
import i18n from "@/i18n/i18n";
import { ComponentsTypes } from "./TipoComponentes";
import TableSelectCodigoDescripcion from "@/components/DinamicallyForm/TableSelectCodigoDescripcion.vue";
import { ButtonAction } from "./ButtonAction";
import Dinamically from "./HelperDinamicallyForm";
import { BaseDto } from "@/shared/dtos/base-dto";
import DxDateBox from "devextreme-vue/date-box";

@Component({
  components: { DxDateBox },
  $_veeValidate: { validator: "new" },
})
export default class DinamicallyForm extends Vue {
  @PropSync("FieldsForView", {
    required: true,
  })
  Fields!: FormItem[];

  @Prop({
    required: false,
    default: false,
  })
  FormReadOnly!: boolean;
  @Prop({
    required: false,
    default: true,
  })
  BotonGuardar!: boolean;
  @Prop({
    required: false,
    default: false,
  })
  BotonArchivar!: boolean;
  @Prop({
    required: false,
    default: true,
  })
  BotonCancelar!: boolean;
  @Prop({
    required: false,
    default: true,
  })
  BotonClonar!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  AutoSaveData!: boolean;
  @Prop({
    required: false,
    default: () => [],
  })
  textos_tabs!: string[];
  @Prop() value!: any;
  /*@Prop({
    required: false,
    default: () => [],
  })
  ButtonActions!: ButtonAction[];*/

  @Prop({
    required: false,
    default: "guardar",
  })
  NombreBotonGuardar!: string;

  @Ref("tabla_codigo_descripcion")
  ComponenteCodDesc!: TableSelectCodigoDescripcion;

  public modeltab = null;
  public auto_generar_codigo: boolean = false;
  public Helper: Dinamically = new Dinamically();

  private FieldRows: any[] = [];
  private FieldsForRow: FormItem[] = [];
  private contador: number = 1;
  private indexcontador: number = -1;
  private indexfocused: number = 0;

  private get ButtonActions() {
    return this.Helper.ActionButtons;
  }

  private get NombrePantalla(): string {
    try {
      return i18n.t(this.$router.currentRoute.meta.i18n).toString();
    } catch (error) {}
    return "";
  }

  private get FieldsForm() {
    for (let i = 0; i < this.Fields.length; i++) {
      if (!this.Fields[i].readonly) {
        this.Fields[i].readonly = this.FormReadOnly;
      }
      if (this.Fields[i].visible) {
        this.indexcontador++;
        this.Fields[i].index = this.indexcontador;
      }
    }
    return this.Fields;
  }

  private get DataRowsForm() {
    this.FieldRows = [];

    for (let i = 0; i < this.Fields.length; i++) {
      this.Fields[i].readonly = this.FormReadOnly;
      if (this.Fields[i].visible) {
        this.indexcontador++;
        this.Fields[i].index = this.indexcontador;
        this.FieldsForRow.push(this.Fields[i]);
      }

      if (this.FieldsForRow.length === 3) {
        this.contador++;
        this.FieldRows.push({ id: this.contador, fields: this.FieldsForRow });
        this.FieldsForRow = [];
      }
    }

    if (this.FieldRows.length !== 0) {
      this.contador++;
      this.FieldRows.push({ id: this.contador, fields: this.FieldsForRow });
      this.FieldsForRow = [];
    }

    return this.FieldRows;
  }

  private get total_action_buttons_visible() {
    return this.ButtonActions.filter((x: ButtonAction) => x.visible === true)
      .length;
  }

  private get totaltabs() {
    let tabs = 1;
    for (let i = 0; i < this.Fields.length; i++) {
      if (this.Fields[i].visible) {
        tabs = Math.max(tabs, this.Fields[i].intab);
      }
    }
    return tabs;
  }
  private get tabsnames() {
    let names: string[] = [];
    for (let i = 0; i < this.totaltabs; i++) {
      names.push(this.getCaptionTab(i));
    }
    return names;
  }

  public ClearDataFrom() {
    try {
      for (let i = 0; i < this.Fields.length; i++) {
        const elementSave = this.Fields[i];
        elementSave.data = undefined;
      }
    } catch (error) {}
  }

  public Save() {
    if (!this.FormReadOnly) {
      this.$validator
        .validateAll()
        .then((allOk) => {
          if (allOk) {
            let obj = this.GetObjectForm();
            this.$emit(EventGuardar, obj);
          } else {
            this.$emit(EventDontSave, null);
          }
        })
        .catch((data) => {
          this.$emit(EventDontSave, data);
        });
    } else {
      this.$emit(EventCancelar, null); //Cuando son ReadOnly debe realizar la misma accion el boton de Guardar que el de Cancelar, no realizar EventDontSave
    }
  }

  public ValidatePromise(): Promise<any> {
    return this.$validator.validateAll();
  }

  public GetObjectForm() {
    let obj = {};
    for (let i = 0; i < this.Fields.length; i++) {
      this.UpdateMayusculas(this.Fields[i]);

      if (this.Fields[i].componenttype === ComponentsTypes.Switch) {
        this.Fields[i].data = UtilsString.booleanToNumber(this.Fields[i].data);
      } else if (
        this.Fields[i].componenttype === ComponentsTypes.TablaCodigoDescripcion
      ) {
        // anteriormente en el back haría falta esto pero ahora ya no

        if (this.Fields[i].data === null) {
          this.Fields[i].data = 0;
        }
      } else if (this.Fields[i].componenttype === ComponentsTypes.Date) {
        var dateFormatted = UtilsString.ValueOf(this.Fields[i].dateFormatted);
    
        if (UtilsString.IsNullOrWhiteSpace(this.Fields[i].dateFormatted)) {
          dateFormatted = "";
        }
        this.Fields[i].data = UtilsString.ValueOf(
          this.adjustDateFormat(this.Fields[i].data, dateFormatted).replace(
            /\//g,
            "-"
          )
        );
      }

      Object.defineProperty(obj, this.Fields[i].variableName, {
        value: this.Fields[i].data,
        writable: true,
        enumerable: true,
        configurable: true,
      });
    }
    return obj;
  }

  adjustDateFormat(data: string, dateFormatted: string): string {
    // Formatos de entrada
    const inputFormat = /(\d{4})-(\d{2})-(\d{2})/; // Formato "yyyy-MM-dd" para `data`
    const outputFormat = /(\d{2})\/(\d{2})\/(\d{4})/; // Formato "dd/MM/yyyy" para `dateFormatted`
    // Convertir `data` a Date
    const [, year, month, day] = data.match(inputFormat) || [];
    const dateData = new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day)
    ); // Meses son 0-indexados

    // Convertir `dateFormatted` a Date
    const [, dayFormatted, monthFormatted, yearFormatted] =
      dateFormatted.match(outputFormat) || [];
    const dateFormattedDate = new Date(
      parseInt(yearFormatted),
      parseInt(monthFormatted) - 1,
      parseInt(dayFormatted)
    ); // Meses son 0-indexados

    return `${yearFormatted}-${monthFormatted.padStart(
      2,
      "0"
    )}-${dayFormatted.padStart(2, "0")}`;

    // Comparar las fechas formateadas
    if (dateData.getTime() !== dateFormattedDate.getTime()) {
      // Si son diferentes, reformatear `dateFormatted` al formato de `data`
      return `${yearFormatted}-${monthFormatted.padStart(
        2,
        "0"
      )}-${dayFormatted.padStart(2, "0")}`;
    }

    /* // Comparar las fechas formateadas
    if (dateData.getTime() !== dateFormattedDate.getTime()) {
      // Si son diferentes, reformatear `dateFormatted` al formato deseado "dd-MM-yyyy"
      return `${dayFormatted.padStart(2, "0")}-${monthFormatted.padStart(
        2,
        "0"
      )}-${yearFormatted}`;
    }
*/
    // Si son iguales, retornar el original `dateFormatted`
    return dateFormatted;
  }

  public actualizaDatos() {
    //Si tiene el v-model empiezo a lanzar eventos, sino no.
    if (this.value !== undefined) {
      this.$emit("input", new BaseDto(this.GetObjectForm()));
    }
  }

  @Watch("value")
  public actualizaDatosDeCodigoAVista() {
    if (this.value !== undefined) {
      for (let i = 0; i < this.Fields.length; i++) {
        this.Fields[i].data = this.value[this.Fields[i].variableName];
      }
    }
  }

  public ArchivarNotificacion() {
    this.$emit(EventArchivar);
  }

  public Cancelar() {
    this.$emit(EventCancelar);
  }

  public Clonar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        this.$emit(EventClonar, this.GetObjectForm());
      }
    });
  }

  public UpdateMayusculas(field: FormItem) {
    if (field.datatype === datatypes.string && field.mayuscula) {
      if (field.data !== undefined) {
        field.data = field.data.toString().toUpperCase();
      }
    }
  }
  public getCaptionTab(indextab: number) {
    if (this.textos_tabs.length === 0 || this.textos_tabs.length === indextab) {
      return "Sin definir nombre";
    }
    return this.textos_tabs[indextab];
  }

  private GetValidation(field: FormItem) {
    let validacion = "";
    if (field.Required) {
      validacion = "required";
    }
    if (field.validacion.toString().trim().length !== 0) {
      if (validacion !== "") {
        validacion += "|";
      }
      validacion += field.validacion;
    }
    return validacion;
  }

  private click(e: any, field: any) {
    this.ChangeValue(e, field);
  }
  private UpCodigo(e: any, field: FormItem) {
    this.UpdateMayusculas(field);
    this.ChangeValue(e, field);
  }
  private keydownEvent(e: KeyboardEvent, field: FormItem) {
    if (
      KeyboardKeyCodes.e === e.keyCode &&
      field.datatype === datatypes.number
    ) {
      e.preventDefault();
    }
    this.UpdateMayusculas(field);
    if (KeyboardKeyCodes.enter === e.keyCode) {
      e.preventDefault();
      this.indexfocused = field.index;
      this.indexfocused++;
      if (this.indexcontador < this.indexfocused) {
        this.indexfocused = 0;
      }
      let textboxref = "text-field-" + this.indexfocused;

      try {
        //@ts-ignore
        this.$refs[textboxref][0].focus();
      } catch (error) {}
    }
  }

  private AutoGenerateCode() {
    this.auto_generar_codigo = !this.auto_generar_codigo;
    this.Fields.filter((x: FormItem) => x.isCodigo === true)[0].isRequired(
      !this.auto_generar_codigo
    );
    if (this.auto_generar_codigo) {
      this.Fields.filter((x: FormItem) => x.isCodigo === true)[0].data = "";
    } else {
      this.Fields.filter(
        (x: FormItem) => x.isCodigo === true
      )[0].data = this.Fields.filter(
        (x: FormItem) => x.isCodigo === true
      )[0].Olddata;
    }
    this.Fields.filter((x: FormItem) => x.isCodigo === true)[0].isReadOnly(
      this.auto_generar_codigo
    );
  }
  private get ThereAreCode() {
    return this.Fields.filter((x: FormItem) => x.isCodigo === true).length > 0;
  }
  private changedate(e: any, field: FormItem) {
    if (!this.ValidateDate(field.data)) {
      field.data = undefined;
      field.OpenPopupMenu = false;
      field.dateFormatted = null;
      return;
    }
    field.OpenPopupMenu = false;
    field.dateFormatted = this.formatDate(field.data);

    this.actualizaDatos();
  }
  private formatDate(date: any) {
    if (!date) return null;
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  private ValidateDate(date: any): boolean {
    try {
      if (UtilsString.ValueOf(date).includes("/")) {
        // si contiene barras estará 01/02/2020
        //spliteamos y cogermos por separado
        let Fecha_str = UtilsString.ValueOf(date).split("/");
        //01
        //02
        //2020
        if (Fecha_str.length !== 3) {
          return false;
        }
        try {
          let dia = Number.parseInt(Fecha_str[0]);
          let mes = Number.parseInt(Fecha_str[1]);
          let ano = Number.parseInt(Fecha_str[2]);
          date = new Date(ano, mes, dia);
        } catch (error) {
          return false;
        }
      }
      let fecha = new Date(date);
      if (Object.prototype.toString.call(fecha) === "[object Date]") {
        // it is a date
        if (isNaN(fecha.getTime())) {
          // d.valueOf() could also work
          // date is not valid
          return false;
        } else {
          // date is valid
          return true;
        }
      } else {
        // not a date
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  private parseDate(date: any, field: FormItem) {
    if (!this.ValidateDate(date)) {
      field.data = undefined;
      field.dateFormatted = null;
      field.OpenPopupMenu = false;
      return undefined;
    }

    if (!date) return null;
    const [day, month, year] = date.split("/");
    return `${year}-${UtilsString.PadStart(
      month,
      "0",
      2
    )}-${UtilsString.PadStart(day, "0", 2)}`;
  }
  private GetRefField(field: FormItem) {
    return "text-field-" + field.index;
  }
  private ChangeValue(e: any, field: FormItem) {
    if (field.componenttype === ComponentsTypes.TablaCodigoDescripcion) {
      if (field.data === 0) {
        field.data = null;
        this.actualizaDatos();
      }
    }
    //Enviar el nombre de la variable y (el valor anterior) y el nuevo valor, si el valido y todo el Obj validador
    field.AllDataChanged.push(e);
    let IsValid = true;
    for (let i = 0; i < this.$validator.errors.items.length; i++) {
      const elementError = this.$validator.errors.items[i];
      for (let j = 0; j < this.Fields.length; j++) {
        const elementField = this.Fields[j];
        if (elementField.name === elementError.field) {
          IsValid = false;
        }
      }
    }
    this.actualizaDatos();

    let ChangeValue: DynamicChangeEventClass = new DynamicChangeEventClass();
    ChangeValue.OldValue = field.Olddata;
    ChangeValue.NewValue = e;
    console.log(e, field);
    ChangeValue.IsValid = IsValid;
    ChangeValue.Validator = this.$validator;
    ChangeValue.FormItem = field;
    ChangeValue.VariableName = field.variableName;
    ChangeValue.FormItems = this.Fields;
    this.$emit(EventChange, ChangeValue);
    this.$emit(ChangeValue.VariableName, ChangeValue);
  }
  private GetCaptionForSwitch(field: FormItem): string {
    let caption = "";
    if (field.UseCaptionForSwitch) {
      return field.caption;
    } else {
      if (field.data) {
        caption = field.SwitchOn;
      } else {
        caption = field.SwitchOff;
      }
    }

    if (field.Required) {
      caption = caption + " *";
    }
    return caption;
  }
  private getClassCss(field: FormItem): string {
    if (field.mayuscula) {
      return "mayusculas";
    }
    return "";
  }
  private get getstyle() {
    let num =
      this.$vuetify.breakpoint.height - (this.totaltabs === 1 ? 303 : 340);
    return "max-height:" + 1600 + "px; overflow-x: hidden; overflow-y: auto;";
  }

  public eventTabName(tabname: any) {
    this.$emit("onClickGetTabName", tabname.nametab);
  }
}
//info
//https://coderwall.com/p/m-rmpq/dynamically-creating-properties-on-objects-using-javascript
// Más información del metodos Object
//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
