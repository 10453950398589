import { Validator } from 'vee-validate';
import { FormItem } from './FormItem';

export class DynamicChangeEventClass {
    public NewValue:any='';
    public OldValue:any='';
    public IsValid:boolean=true;
    public Validator!:Validator;
    public FormItem!:FormItem;
    public FormItems!:FormItem[];
    public VariableName:string='';

}