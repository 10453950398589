export class datatypes {
    /*Tipos de datos*/
    public static number = 'number';
    public static boolean = 'boolean';
    public static string = 'string';
    public static date = 'date';
    public static datetime = 'datetime';
    /*Centrado de columnas*/
    public static right = 'right';//number  por defecto Devextreme alinea segun el tipo
    public static center = 'center';//boolean por defecto Devextreme alinea segun el tipo
    public static left = 'left';//string,date,datetime por defecto Devextreme alinea segun el tipo
    /*longitudes maximas*/
    public static maxlength: number = 9999999;
    public static idlength: number = 20;
    public static maxlengthDate: number = 10;
    public static maxlengthDateTime: number = 15;
    public static maxlengthinteger: number = 12;
    public static maxlengthdecimal: number = 20;
    public static maxCodigo: number = 15;
    public static maxDescripcion: number = 100;
    public static maxlengthShort: number = 5;

}